import {Button, Flex, Modal} from "antd";
import React, { useState } from 'react';
import {UploadFile} from "./UploadFile";
import {TakePhoto} from "../TakePhoto";
import {useParams} from "react-router-dom";
import {AttachmentType, UploadAttachment} from "../../../models/Practice/PracticeFIle";
import {PracticesHttpService} from "../../../services/Http/PracticesHttpService";
import {NotificationService} from "../../../services/NotificationService";
import {InsuranceProviderEclaim} from "../../../models/InsuranceProvider";
import {ScanDocument} from "./ScanDocument";

export interface UploadFilesModalProps{
    isModalOpen:boolean
    onClose:()=>void
    attachmentData : UploadAttachment
    isInvoice?: boolean;
    insuranceProvider: InsuranceProviderEclaim
}
export function UploadFilesModal(props:UploadFilesModalProps){
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const PREVIMEDICAL_MAX_SIZE_MB = 4;
    const PREVIMEDICAL_MAX_FILE_SIZE = PREVIMEDICAL_MAX_SIZE_MB * 1024 * 1024;

    let {id} = useParams();
    const handleClose = () => {
        props.onClose();
        setErrorMessage(null);
    }

    const isValidFileSize = (size: Number) => {
        if (props.insuranceProvider.integrationCode === 'PREVIMEDICAL' && (size > PREVIMEDICAL_MAX_FILE_SIZE)) {
            return false;
        }
        return true;
    }

    const validExtensions = () => {
        if (props.insuranceProvider.integrationCode === 'PREVIMEDICAL') {
            return '.pdf,image/jpeg,image/png,image/tiff';
        }
        return '';
    }

    const beforeUpload = (file: File) => {
        if (!isValidFileSize(file.size)) {
            setErrorMessage('Il file è troppo grande. La dimensione massima consentita è di ' + PREVIMEDICAL_MAX_SIZE_MB + ' MB.');
            return false;
        }
        setErrorMessage(null);
        return true;
    };

    async function onChangeTakePhoto(photoDataUri: string){
        const response = await fetch(photoDataUri);
        const file = await response.blob();
        if (!isValidFileSize(file.size)) {
            let uploadedSize = (file.size / (1024 * 1024))
            setErrorMessage('Il file immagine è troppo grande (' + Number(uploadedSize).toFixed(2) + ' MB). La dimensione massima consentita è di ' + PREVIMEDICAL_MAX_SIZE_MB + ' MB.');
            return false;
        }
        const formData = new FormData();
        formData.append('attachmentData', JSON.stringify(props.attachmentData));
        formData.append('file', file);
        try {
            const response = await PracticesHttpService.uploadPracticeFiles(id,formData);
            const compressed = response.data ? response.data.compressed : false;
            const message = compressed ? "File compresso e salvato correttamente" : "File caricato correttamente";
            NotificationService.getInstance().openNotificationSuccess(message);
            props.onClose();
        } catch (error:any) {
            let message = "Impossibile caricare file";
            if (error.response.status === 400) {
                const data = error.response.data;
                message = data.message;
            }
            NotificationService.getInstance().openNotificationError(message);
        }
    }
    function onUpload(){
        props.onClose();
        setErrorMessage(null);
    }
    return <>
        <Modal
            width={820}
            closable={true}
            open={props.isModalOpen}
            title={props.isInvoice ? "Caricamento fattura" : "Caricamento documenti"}
            onCancel={handleClose}
            footer={(_, { OkBtn, CancelBtn }) => (
                <>
                    <Button onClick={handleClose} htmlType="reset">Chiudi</Button>
                </>
            )}
        >
            <Flex justify={"space-around"}>
                <UploadFile id={id} attachmentData={props.attachmentData}
                            onUpload={onUpload} beforeUpload={beforeUpload} acceptTypes={validExtensions()}></UploadFile>
                <TakePhoto onChange={onChangeTakePhoto}></TakePhoto>
                {/*{ isInvoice && <ScanDocument onChange={onChangeTakePhoto} /> }*/}
            </Flex>
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        </Modal>
    </>
}