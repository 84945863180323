import { Button, Modal, Image } from "antd";
import { useState } from "react";
import Camera, { FACING_MODES } from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";

export function TakePhoto(props: {onChange: (photoDataUri: string, scan: boolean) => void}) {
  const [isModalOpen, setModalOpen] = useState(false);
  const [photo, setPhoto] = useState("");

  const handleTakePhoto = (dataUri: string) => {
    setTimeout(() => {
      setPhoto(dataUri);
    }, 200)
  };

  const onOk = () => {
    props.onChange(photo, false);

    setModalOpen(false)
    setPhoto('');
  }

  const onCancel = () => {
    setModalOpen(false)
    setPhoto('');
  }

  const deletePhoto = () => {
    setPhoto('');
  }

  return (
    <>
      <Button type="primary" onClick={() => setModalOpen(true)}>Scatta foto</Button>
      <Modal
        width={820}
        closable={false}
        open={isModalOpen}
        onCancel={onCancel}
        footer={[
          <Button key="close" onClick={onCancel}>
            Chiudi
          </Button>,
          photo ? (<Button key="back" onClick={deletePhoto}>Scatta un'altra foto</Button>) : null,
          <Button key="submit" type="primary" disabled={!photo} onClick={onOk}>
            Conferma
          </Button>,
        ]}
      >
        {photo ? (
          <Image preview={false} src={photo} />
        ) : (
          <Camera
            onTakePhoto={handleTakePhoto}
            idealFacingMode={FACING_MODES.ENVIRONMENT}
            isMaxResolution={true}
          />
        )}
      </Modal>
    </>
  );
}