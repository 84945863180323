// @ts-ignore
// @ts-ignore

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import {ConfigProvider} from "antd";
import {Provider} from "react-redux";
import {store} from "./store/Store";
import "./translate/Translate"
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import locale from 'antd/es/locale/it_IT';

import "./cvr";

import 'dayjs/locale/it';
import dayjs from "dayjs";

dayjs.locale('it');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// @ts-expect-error This does not exist outside of polyfill which this is doing
if (typeof Promise.withResolvers === 'undefined') {
    if (window)
        // @ts-expect-error This does not exist outside of polyfill which this is doing
        window.Promise.withResolvers = function () {
            let resolve, reject;
            const promise = new Promise((res, rej) => {
                resolve = res;
                reject = rej;
            });
            return { promise, resolve, reject };
        };
}

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//     'pdfjs-dist/build/pdf.worker.min.mjs',
//     import.meta.url,
// ).toString();
pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;

const themeConfig = {
    token: {
        colorPrimary: 'var(--main-color)',
    },
    components: {
        Tag: {
            defaultBg:'var(--main-color)',
            defaultColor:'var(--secondary-color)',
        },
        Button: {
            primaryShadow:'0 1px 0 rgba(0, 0, 0, 0.2)'
        },
        Select: {
            optionSelectedColor: 'white'
        }
    },
    hashed: false
}

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Provider store={store}>
                <ConfigProvider theme={themeConfig} locale={locale}>
                    <App />
                </ConfigProvider>
            </Provider>

        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
