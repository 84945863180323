import { Form, Table, type TableProps } from 'antd';
import {InputFloatNumber} from "../InputFloatNumber";

export interface DiottrieTableData {
  key: string;
  type: 'distanza' | 'permanenza' | 'vicino';
  sferaSx?: number;
  cilindroSx?: number;
  asseSx?: number;
  sferaDx?: number;
  cilindroDx?: number;
  asseDx?: number;
}

export function DiottrieTable(props: {prefix: string; isRequired: boolean}) {
  function customRender(key: string, record: DiottrieTableData) {
    const recordName = `${props.prefix}-${record.type}-${key}`.replace(/-./g, x=>x[1].toUpperCase())
    return <Form.Item name={recordName} rules={[{required: props.isRequired, message: 'Inserire valore'}]}><InputFloatNumber /></Form.Item>;
  }
  
  const columns: TableProps<DiottrieTableData>['columns'] = [
    {
      title: '',
      dataIndex: 'type',
      key: 'type',
      render: (text: string) => {
        if (text === 'distanza')
          return 'Per distanza';
        if (text === 'permanenza')
          return 'A permanenza'
        if (text === 'vicino')
          return 'Per vicino'
        return text;
      }
    },
    {
      title: 'Sfera dx',
      dataIndex: 'sferaDx',
      key: 'sferaDx',
      render: (_, record: DiottrieTableData) => {
        return customRender('sferaDx', record);
      }
    },
    {
      title: 'Cilidro dx',
      dataIndex: 'cilindroDx',
      key: 'cilindroDx',
      render: (_, record: DiottrieTableData) => {
        return customRender('cilindroDx', record);
      }
    },
    {
      title: 'Asse dx',
      dataIndex: 'asseDx',
      key: 'asseDx',
      render: (_, record: DiottrieTableData) => {
        return customRender('asseDx', record);
      }
    },
    {
      title: 'Sfera sx',
      dataIndex: 'sferaSx',
      key: 'sferaSx',
      render: (_, record: DiottrieTableData) => {
        return customRender('sferaSx', record);
      }
    },
    {
      title: 'Cilidro sx',
      dataIndex: 'cilindroSx',
      key: 'cilindroSx',
      render: (_, record: DiottrieTableData) => {
        return customRender('cilindroSx', record);
      }
    },
    {
      title: 'Asse sx',
      dataIndex: 'asseSx',
      key: 'asseSx',
      render: (_, record: DiottrieTableData) => {
        return customRender('asseSx', record);
      }
    }
  ]

  const data: DiottrieTableData[] = [
    {
      key: props.prefix + '_distanza',
      type: 'distanza'
    },
    {
      key: props.prefix + '_permanenza',
      type: 'permanenza'
    },
    {
      key: props.prefix + '_vicino',
      type: 'vicino'
    }
  ]

  const key = props.prefix+'_table'

  return (
    <Table key={key} columns={columns} dataSource={data} pagination={false}/>
  )
}